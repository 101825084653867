import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import './Quiz.css'; // Import the regular CSS file
import { questions } from './questions'; // Import the questions

function QuizApp() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [currentQuestions, setCurrentQuestions] = useState([]);

  // Helper function to shuffle an array
  const shuffleArray = (array) => {
    let shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  // Function to get 5 random questions
  const getRandomQuestions = () => {
    const shuffledQuestions = shuffleArray(questions);
    return shuffledQuestions.slice(0, 5); // Get the first 5 questions
  };

  // Fetch random questions on mount and reset
  useEffect(() => {
    setCurrentQuestions(getRandomQuestions());
    setCurrentQuestionIndex(0);
    setScore(0);
    setAnsweredQuestions([]);
  }, []); // Empty dependency array ensures this runs only once on mount

  // Handle answering a question
  const handleAnswer = (selectedOption) => {
    const currentQuestion = currentQuestions[currentQuestionIndex];
    const isCorrect = selectedOption === currentQuestion.correctAnswer;
    
    if (isCorrect) {
      setScore(prevScore => prevScore + 1);
    }

    setAnsweredQuestions((prevAnswered) => [
      ...prevAnswered,
      { questionId: currentQuestion.id, selectedAnswer: selectedOption, isCorrect },
    ]);

    const nextQuestionIndex = currentQuestionIndex + 1;
    if (nextQuestionIndex < currentQuestions.length) {
      setCurrentQuestionIndex(nextQuestionIndex);
    } else {
      // Use toast to show the final score
      toast.success(`Quiz Over! Your score is: ${score + (isCorrect ? 1 : 0)} / ${currentQuestions.length}`, {
        autoClose: 5000,
      });
    }
  };

  // Handle quiz reset
  const handleReset = () => {
    setCurrentQuestions(getRandomQuestions()); 
    setCurrentQuestionIndex(0);
    setScore(0);
    setAnsweredQuestions([]);
    toast.info("Quiz has been reset. Good luck!", {
      autoClose: 3000,
    });
  };

  // Get the current question based on index
  const currentQuestion = currentQuestions[currentQuestionIndex];

  return (
    <div className="quizApp">
      <h2 className="quizAppTitle">ISSO Mock Quiz</h2>
      <div className="quizAppQuestion">
        <p>{currentQuestion ? currentQuestion.question : 'Loading question...'}</p>
      </div>
      <div className="quizAppOptions">
        {currentQuestion?.options.map((option, index) => (
          <button
            key={index}
            className="quizAppOptionButton"
            onClick={() => handleAnswer(option)}
          >
            {option}
          </button>
        ))}
      </div>
      <div className="quizAppScore">
        <p>Score: {score}</p>
        <p>Question {currentQuestionIndex + 1} of {currentQuestions.length}</p>
      </div>
      
      <button className="resetButton" onClick={handleReset}>Reset Quiz</button>

      {/* Toast container for showing notifications */}
      <ToastContainer />
    </div>
  );
}

export default QuizApp;
