export const questions = [
  {
    id: 1,
    question: 'Which planet is known as the Red Planet?',
    options: ['Earth', 'Mars', 'Jupiter', 'Venus'],
    correctAnswer: 'Mars',
  },
  {
    id: 2,
    question: 'What is the largest planet in our solar system?',
    options: ['Earth', 'Saturn', 'Jupiter', 'Neptune'],
    correctAnswer: 'Jupiter',
  },
  {
    id: 3,
    question: 'Which space agency launched the Hubble Space Telescope?',
    options: ['NASA', 'ISRO', 'ESA', 'Roscosmos'],
    correctAnswer: 'NASA',
  },
  {
    id: 4,
    question: 'What is the speed of light?',
    options: ['3 x 10^8 m/s', '2.5 x 10^8 m/s', '3 x 10^6 m/s', '1 x 10^8 m/s'],
    correctAnswer: '3 x 10^8 m/s',
  },
  {
    id: 5,
    question: 'What is the closest star to Earth?',
    options: ['Sirius', 'Alpha Centauri', 'Proxima Centauri', 'The Sun'],
    correctAnswer: 'The Sun',
  },
  {
    id: 6,
    question: 'What is the name of our galaxy?',
    options: ['Andromeda', 'Milky Way', 'Triangulum', 'Whirlpool'],
    correctAnswer: 'Milky Way',
  },
  {
    id: 7,
    question: 'What does NASA stand for?',
    options: ['National Aeronautics and Space Agency', 'National Aeronautics and Space Administration', 'National Air and Space Agency', 'National Aerospace and Space Administration'],
    correctAnswer: 'National Aeronautics and Space Administration',
  },
  {
    id: 8,
    question: 'Who was the first human to travel into space?',
    options: ['Neil Armstrong', 'Yuri Gagarin', 'Buzz Aldrin', 'Alan Shepard'],
    correctAnswer: 'Yuri Gagarin',
  },
  {
    id: 9,
    question: 'Which planet has the longest day?',
    options: ['Venus', 'Earth', 'Mars', 'Jupiter'],
    correctAnswer: 'Venus',
  },
  {
    id: 10,
    question: 'What is the main component of the Sun?',
    options: ['Oxygen', 'Helium', 'Hydrogen', 'Carbon'],
    correctAnswer: 'Hydrogen',
  },
  {
    id: 11,
    question: 'What is the name of the first artificial Earth satellite?',
    options: ['Apollo 11', 'Sputnik 1', 'Vostok 1', 'Explorer 1'],
    correctAnswer: 'Sputnik 1',
  },
  {
    id: 12,
    question: 'Which planet is known for having a great red spot?',
    options: ['Saturn', 'Uranus', 'Jupiter', 'Neptune'],
    correctAnswer: 'Jupiter',
  },
  {
    id: 13,
    question: 'How many moons does Earth have?',
    options: ['1', '2', '3', '4'],
    correctAnswer: '1',
  },
  {
    id: 14,
    question: 'Which planet is closest to the Sun?',
    options: ['Earth', 'Venus', 'Mercury', 'Mars'],
    correctAnswer: 'Mercury',
  },
  {
    id: 15,
    question: 'What is the name of NASA’s Mars Rover that landed on Mars in 2021?',
    options: ['Curiosity', 'Perseverance', 'Spirit', 'Opportunity'],
    correctAnswer: 'Perseverance',
  },
  {
    id: 16,
    question: 'What type of galaxy is the Milky Way?',
    options: ['Elliptical', 'Spiral', 'Irregular', 'Lenticular'],
    correctAnswer: 'Spiral',
  },
  {
    id: 17,
    question: 'What is the term for a rock that enters Earth’s atmosphere from space?',
    options: ['Asteroid', 'Meteorite', 'Comet', 'Meteor'],
    correctAnswer: 'Meteor',
  },
  {
    id: 18,
    question: 'Which planet has the most moons?',
    options: ['Saturn', 'Jupiter', 'Uranus', 'Neptune'],
    correctAnswer: 'Saturn',
  },
  {
    id: 19,
    question: 'What is the largest moon in the solar system?',
    options: ['Io', 'Titan', 'Ganymede', 'Europa'],
    correctAnswer: 'Ganymede',
  },
  {
    id: 20,
    question: 'What is the hottest planet in our solar system?',
    options: ['Mercury', 'Venus', 'Mars', 'Jupiter'],
    correctAnswer: 'Venus',
  },
  {
    id: 21,
    question: 'What is a black hole?',
    options: ['A dark region with no light', 'A star that has exploded', 'A dense object with immense gravity', 'A planet without any atmosphere'],
    correctAnswer: 'A dense object with immense gravity',
  },
  {
    id: 22,
    question: 'Who was the first person to set foot on the Moon?',
    options: ['Buzz Aldrin', 'Yuri Gagarin', 'Neil Armstrong', 'John Glenn'],
    correctAnswer: 'Neil Armstrong',
  },
  {
    id: 23,
    question: 'Which planet has the shortest day?',
    options: ['Earth', 'Saturn', 'Jupiter', 'Neptune'],
    correctAnswer: 'Jupiter',
  },
  {
    id: 24,
    question: 'What is the name of the first woman to travel into space?',
    options: ['Mae Jemison', 'Valentina Tereshkova', 'Sally Ride', 'Eileen Collins'],
    correctAnswer: 'Valentina Tereshkova',
  },
  {
    id: 25,
    question: 'Which is the smallest planet in our solar system?',
    options: ['Mercury', 'Mars', 'Venus', 'Earth'],
    correctAnswer: 'Mercury',
  },
  {
    id: 26,
    question: 'What is the primary gas in the Earth’s atmosphere?',
    options: ['Oxygen', 'Nitrogen', 'Carbon Dioxide', 'Hydrogen'],
    correctAnswer: 'Nitrogen',
  },
  {
    id: 27,
    question: 'What is the name of NASA’s space telescope that was launched in 1990?',
    options: ['Hubble', 'James Webb', 'Kepler', 'Spitzer'],
    correctAnswer: 'Hubble',
  },
  {
    id: 28,
    question: 'Which of these planets has a ring system?',
    options: ['Venus', 'Mars', 'Saturn', 'Earth'],
    correctAnswer: 'Saturn',
  },
  {
    id: 29,
    question: 'How many planets are there in our solar system?',
    options: ['7', '8', '9', '10'],
    correctAnswer: '8',
  },
  {
    id: 30,
    question: 'What is the largest asteroid in the asteroid belt?',
    options: ['Ceres', 'Vesta', 'Pallas', 'Juno'],
    correctAnswer: 'Ceres',
  },
  {
    "id": 21,
    "question": "What is the largest moon of Saturn?",
    "options": ["Titan", "Ganymede", "Callisto", "Europa"],
    "correctAnswer": "Titan"
  },
  {
    "id": 22,
    "question": "Which galaxy is closest to the Milky Way?",
    "options": ["Andromeda", "Triangulum", "Whirlpool", "Sombrero"],
    "correctAnswer": "Andromeda"
  },
  {
    "id": 23,
    "question": "What is the name of the first artificial satellite launched into space?",
    "options": ["Sputnik 1", "Apollo 11", "Vostok 1", "Explorer 1"],
    "correctAnswer": "Sputnik 1"
  },
  {
    "id": 24,
    "question": "Which planet is known for its extensive ring system?",
    "options": ["Saturn", "Jupiter", "Uranus", "Neptune"],
    "correctAnswer": "Saturn"
  },
  {
    "id": 25,
    "question": "What is the name of the first human to walk on the moon?",
    "options": ["Neil Armstrong", "Buzz Aldrin", "Yuri Gagarin", "Alan Shepard"],
    "correctAnswer": "Neil Armstrong"
  },
  {
    "id": 26,
    "question": "Which space telescope has helped astronomers gather a wealth of knowledge about deep space?",
    "options": ["Hubble Space Telescope", "James Webb Space Telescope", "Chandra X-ray Observatory", "Spitzer Space Telescope"],
    "correctAnswer": "Hubble Space Telescope"
  },
  {
    "id": 27,
    "question": "Which planet has the longest day?",
    "options": ["Venus", "Mars", "Jupiter", "Mercury"],
    "correctAnswer": "Venus"
  },
  {
    "id": 28,
    "question": "What is the largest star in the known universe?",
    "options": ["Betelgeuse", "VY Canis Majoris", "Sirius", "Rigel"],
    "correctAnswer": "VY Canis Majoris"
  },
  {
    "id": 29,
    "question": "Which planet is closest to the Sun?",
    "options": ["Mercury", "Venus", "Earth", "Mars"],
    "correctAnswer": "Mercury"
  },
  {
    "id": 30,
    "question": "How many moons does Mars have?",
    "options": ["1", "2", "3", "4"],
    "correctAnswer": "2"
  },
  {
    "id": 31,
    "question": "What is the main component of the Sun?",
    "options": ["Helium", "Oxygen", "Hydrogen", "Carbon"],
    "correctAnswer": "Hydrogen"
  },
  {
    "id": 32,
    "question": "Which planet is known as the 'Earth's twin' due to its similar size and mass?",
    "options": ["Venus", "Mars", "Mercury", "Jupiter"],
    "correctAnswer": "Venus"
  },
  {
    "id": 33,
    "question": "What phenomenon causes a solar eclipse?",
    "options": ["Earth's shadow", "Moon's shadow", "Sun's flare", "Planetary alignment"],
    "correctAnswer": "Moon's shadow"
  },
  {
    "id": 34,
    "question": "What is the largest volcano in the solar system?",
    "options": ["Olympus Mons", "Mount Everest", "Mount Kilimanjaro", "Vesuvius"],
    "correctAnswer": "Olympus Mons"
  },
  {
    "id": 35,
    "question": "Which planet has a tilted axis that causes extreme seasons?",
    "options": ["Uranus", "Mars", "Neptune", "Jupiter"],
    "correctAnswer": "Uranus"
  },
  {
    "id": 36,
    "question": "Which planet has the most moons?",
    "options": ["Saturn", "Jupiter", "Uranus", "Neptune"],
    "correctAnswer": "Jupiter"
  },
  {
    "id": 37,
    "question": "What is the name of the NASA rover that landed on Mars in 2021?",
    "options": ["Curiosity", "Perseverance", "Spirit", "Opportunity"],
    "correctAnswer": "Perseverance"
  },
  {
    "id": 38,
    "question": "What is the Kuiper Belt?",
    "options": ["A region of icy objects beyond Neptune", "A belt of asteroids between Mars and Jupiter", "A region around the Sun", "The space between Earth and Mars"],
    "correctAnswer": "A region of icy objects beyond Neptune"
  },
  {
    "id": 39,
    "question": "What is the primary element in Earth's atmosphere?",
    "options": ["Oxygen", "Carbon Dioxide", "Nitrogen", "Argon"],
    "correctAnswer": "Nitrogen"
  },
  {
    "id": 40,
    "question": "Which spacecraft was the first to land on the surface of a comet?",
    "options": ["Rosetta", "Viking 1", "Spirit", "Opportunity"],
    "correctAnswer": "Rosetta"
  },
  {
    "id": 41,
    "question": "Which planet is known as the 'Gas Giant'?",
    "options": ["Saturn", "Jupiter", "Neptune", "Uranus"],
    "correctAnswer": "Jupiter"
  },
  {
    "id": 42,
    "question": "What is the main element in the Sun's core?",
    "options": ["Hydrogen", "Oxygen", "Helium", "Carbon"],
    "correctAnswer": "Hydrogen"
  },
  {
    "id": 43,
    "question": "What was the first spacecraft to visit Pluto?",
    "options": ["Voyager 1", "New Horizons", "Parker Solar Probe", "Cassini"],
    "correctAnswer": "New Horizons"
  },
  {
    "id": 44,
    "question": "Which planet has the most eccentric orbit?",
    "options": ["Earth", "Mercury", "Pluto", "Mars"],
    "correctAnswer": "Pluto"
  },
  {
    "id": 45,
    "question": "What is the name of the first woman in space?",
    "options": ["Valentina Tereshkova", "Sally Ride", "Mae Jemison", "Eileen Collins"],
    "correctAnswer": "Valentina Tereshkova"
  },
  {
    "id": 46,
    "question": "Which planet has the shortest year?",
    "options": ["Venus", "Mars", "Mercury", "Neptune"],
    "correctAnswer": "Mercury"
  },
  {
    "id": 47,
    "question": "What is the name of the biggest asteroid in the asteroid belt?",
    "options": ["Ceres", "Vesta", "Pallas", "Eros"],
    "correctAnswer": "Ceres"
  },
  {
    "id": 48,
    "question": "Which spacecraft was the first to land on the surface of Mars?",
    "options": ["Spirit", "Viking 1", "Perseverance", "Opportunity"],
    "correctAnswer": "Viking 1"
  },
  {
    "id": 49,
    "question": "What is the speed of light in a vacuum?",
    "options": ["300,000 km/s", "300,000 m/s", "150,000 km/s", "150,000 m/s"],
    "correctAnswer": "300,000 km/s"
  },
  {
    "id": 50,
    "question": "What is the name of the first human to travel into space?",
    "options": ["Yuri Gagarin", "Neil Armstrong", "Alan Shepard", "Buzz Aldrin"],
    "correctAnswer": "Yuri Gagarin"
  }
];
