// src/pages/Policies.js
import React from 'react';
import Footer from './Footer';
import './policies.css';

const Policies = () => {
  return (
    <div className="policies-page">

      <div className="policies-content">
        <h1>Privacy Policies / Terms & Conditions</h1>

        <section>
          <h2>Introduction</h2>
          <p>We value your privacy and are committed to protecting your personal data. This Privacy Policy explains how we collect, use, and protect your personal information when you use our website and services.</p>
        </section>

        <section>
          <h2>Information We Collect</h2>
          <p>We collect information that you provide directly to us, such as when you sign up for an account or make a purchase. We may also collect certain technical information automatically when you use our website, such as your IP address, browser type, and device information.</p>
        </section>

        <section>
          <h2>How We Use Your Information</h2>
          <p>We use your information to provide and improve our services, send you updates, and respond to your inquiries. We do not sell or share your personal data with third parties without your consent.</p>
        </section>

        <section>
          <h2>Data Security</h2>
          <p>We implement industry-standard security measures to protect your data. However, no system is completely secure, and we cannot guarantee the absolute security of your information.</p>
        </section>

        <section>
          <h2>Your Rights</h2>
          <p>You have the right to access, update, and delete your personal data. </p>
        </section>

        <section>
          <h2>Cookies</h2>
          <p>We use cookies to enhance your experience on our website. You can choose to disable cookies in your browser settings, but this may affect some features of the website.</p>
        </section>

        <section>
          <h2>Changes to This Policy</h2>
          <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the effective date will be updated at the top of the page.</p>
        </section>

        <section>
          <h2>No Refunds on Services</h2>
          <p>All services provided by **CodiMaths**, including but not limited to online courses, consultations, Olympiad preparation, and educational resources, are non-refundable. Once a service is purchased or a booking is made, it is considered final, and no refunds will be issued.</p>
        </section>

        <section>
          <h2>Contact Us</h2>
          <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:info@codimaths.com">info@codimaths.com</a>.</p>
        </section>
      </div>


    </div>
  );
};

export default Policies;
