import React, { useState, useRef, useEffect } from 'react';
import './otpVerification.css';
import { useUserState } from '../helper';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function OTPVerificationPage() {
  const navigate = useNavigate();
  const [Otp, setOtp] = useState(['', '', '', '']);
  const [isVerified, setIsVerified] = useState(false);
  const { fetch, user, setIsLoggedIn, storeData } = useUserState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const otpRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  // Ensure toasts are shown after reload
  useEffect(() => {
    const errorMessage = sessionStorage.getItem('otpError');
    if (errorMessage) {
      toast.error(errorMessage);
      sessionStorage.removeItem('otpError');
    }
  }, []);

  const handleChange = (index, value, event) => {
    if (/^[0-9]$/.test(value) || value === '') {
      const newOtp = [...Otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value !== '' && index < otpRefs.length - 1) {
        otpRefs[index + 1].current.focus();
      } else if (value === '' && event.key === 'Backspace' && index > 0) {
        otpRefs[index - 1].current.focus();
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const otpCode = Otp.join('');
    if (otpCode.length !== 4) {
      toast.error('Please enter a 4-digit OTP.');
    } else {
      setIsSubmitting(true);
      try {
        const { data, status } = await fetch({
          url: 'olympiad-registration/',
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          data: { email: user.email, otp: otpCode },
        });

        if (status === 200) {
          storeData(data);
          toast.success('Registration successful!');
          navigate('/thank-you');
        } else if (status === 208) {
          storeData(data);
          toast.info('Already registered!');
          navigate('/thank-you');
        }
      } catch (error) {
        sessionStorage.setItem('otpError', error.error || 'Error during OTP verification');
        toast.error(`Error: ${error.error}`);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleResend = async (e) => {
    e.preventDefault();
    try {
      const { data, status } = await fetch({
        url: 'verify-mail/',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: { email: user.email },
      });
      if (status === 200) {
        toast.success('OTP resent successfully!');
      }
    } catch (error) {
      toast.error('Failed to resend OTP. Please try again.');
    }
  };

  return (
    <div className="otp-verification-container">
      <ToastContainer />
      <h2>OTP Verification</h2>
      <p>Enter the 4-digit code sent to your email.</p>

      {!isVerified ? (
        <form onSubmit={handleSubmit}>
          <div className="otp-input-container">
            {Otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                className="otp-input"
                maxLength="1"
                value={digit}
                onChange={(e) => handleChange(index, e.target.value, e)}
                onKeyDown={(e) => handleChange(index, e.target.value, e)}
                disabled={isSubmitting}
                ref={otpRefs[index]}
              />
            ))}
          </div>
          <button type="submit" className="submit-btn" disabled={isSubmitting}>
            {isSubmitting ? 'Verifying...' : 'Verify'}
          </button>
        </form>
      ) : (
        <div className="success-message">
          <div className="tick-mark">✔</div>
          <p>Verification successful! Check your email for the password.</p>
        </div>
      )}

      <button className="resend-link" onClick={handleResend} disabled={isSubmitting}>
        Resend OTP
      </button>
    </div>
  );
}

export default OTPVerificationPage;