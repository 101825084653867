import React from 'react';
import styles from './ThankYou.module.css';

const Thanks = () => {
  return (
    <div className={styles.thankYouContainer}>
      <div className={styles.thankYouContent}>
        <h1 className={styles.thankYouHeading}>Thank You for Registering!</h1>
        <p className={styles.thankYouMessage}>
          Congratulations on successfully registering for the ISSO Olympiad. We are thrilled to have you
          on board. Your registration has been successfully processed, and you are now officially part of
          the competition.
        </p>
        <p className={styles.thankYouMessage}>
          For further details and important updates, please stay tuned and keep an eye on your inbox.
          We will be sending more information via email soon.
        </p>
        <div className={styles.thankYouFooter}>
          Best wishes for the ISSO Olympiad!
        </div>
        

        {/* ISSO Full Form */}
        <div className={styles.thankYouFullForm}>
          ISSO: International Space Science Olympiad, hosted by Codimaths.
        </div>
      </div>
    </div>
  );
}

export default Thanks;
