import React, { useState, useEffect, useCallback } from 'react';
import styles from './SpaceScienceFacts.module.css'; 
import Header from '../Header';

const SpaceScienceFacts = () => {
  const [facts, setFacts] = useState([]);
  const [loading, setLoading] = useState(false);

  // Sample data for space science facts (25 facts in total)
  const spaceFacts = [
    { title: "The Largest Planet", fact: "Jupiter is the largest planet in our solar system, with a diameter of about 142,984 kilometers." },
    { title: "First Human in Space", fact: "Yuri Gagarin, a Russian cosmonaut, was the first human to journey into outer space in 1961." },
    { title: "A Year on Mercury", fact: "A year on Mercury (the time it takes to orbit the Sun) is only 88 Earth days long!" },
    { title: "The Milky Way Galaxy", fact: "The Milky Way galaxy is home to about 100 billion stars, and it is one of at least 100 billion galaxies in the universe." },
    { title: "The Moon's Gravity", fact: "The Moon's gravity is only 1/6th that of Earth's. If you weigh 100kg on Earth, you'd weigh only 16.5kg on the Moon!" },
    { title: "Saturn's Rings", fact: "Saturn's rings are made of ice and rock, and they extend up to 280,000 kilometers from the planet." },
    { title: "Longest Day on Venus", fact: "A day on Venus lasts longer than a year on Venus! It takes 243 Earth days to rotate once, but only 225 Earth days to orbit the Sun." },
    { title: "Mars' Red Appearance", fact: "The red appearance of Mars comes from iron oxide (rust) covering the surface of the planet." },
    { title: "Size of the Sun", fact: "The Sun makes up 99.86% of the mass of the entire solar system. It could fit around 1.3 million Earths inside it!" },
    { title: "The Hubble Space Telescope", fact: "Launched in 1990, the Hubble Space Telescope has provided some of the most detailed images of distant stars and galaxies." },
    { title: "The First Star Explosion", fact: "The first observed supernova, known as SN 1054, was recorded by Chinese astronomers in 1054 AD and became the Crab Nebula." },
    { title: "Neptune's Winds", fact: "Winds on Neptune can reach speeds of up to 2,100 kilometers per hour (1,300 miles per hour), faster than the speed of sound!" },
    { title: "The Largest Volcano", fact: "The largest volcano in the solar system is Olympus Mons on Mars, standing at 21.9 kilometers high (13.6 miles)." },
    { title: "Pluto's Moon Charon", fact: "Pluto's largest moon, Charon, is half the size of Pluto itself, making it one of the largest moon-to-planet ratios in the solar system." },
    { title: "The Andromeda Galaxy", fact: "The Andromeda Galaxy, our nearest large galactic neighbor, is on a collision course with the Milky Way and will merge in about 4.5 billion years." },
    { title: "Earth's Orbit", fact: "Earth's orbit around the Sun is slightly elliptical, making the distance from the Sun vary by about 3.1 million miles." },
    { title: "Black Hole Size", fact: "Some black holes are so massive that they can be billions of times more massive than our Sun, with their event horizon stretching over millions of kilometers." },
    { title: "Voyager Probes", fact: "NASA’s Voyager 1 and 2 spacecraft, launched in 1977, are now the farthest human-made objects from Earth." },
    { title: "The Great Red Spot", fact: "Jupiter's Great Red Spot is a massive storm that has been raging for at least 400 years." },
    { title: "The Speed of Light", fact: "Light travels at approximately 300,000 kilometers per second (186,282 miles per second) and can circle the Earth 7.5 times in one second." },
    { title: "Titan's Atmosphere", fact: "Saturn's moon Titan has a thick atmosphere made mostly of nitrogen, which is the only thick atmosphere found on a moon in the solar system." },
  ];

  // Function to simulate fetching data (you can replace it with real API if necessary)
  const fetchFacts = useCallback(async () => {
    setLoading(true);
    try {
      // Simulate fetching the first batch of data (all facts)
      setFacts(spaceFacts);
    } catch (error) {
      console.error('Error fetching facts:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  // Fetch the facts when the component mounts
  useEffect(() => {
    fetchFacts();
  }, [fetchFacts]);

  return (
    <>
      <Header />
      <div className={styles.spaceFactsContainer}>
        <h1 className={styles.heading}>Space Science Fun Facts</h1>
        {loading ? (
          <div className={styles.spinner}>
            <p className={styles.loadingText}>Wait for the facts, Champ!</p>
          </div>
        ) : (
          <div className={styles.factList}>
            {facts.map((fact, index) => (
              <div key={index} className={styles.factItem}>
                <h3 className={styles.factItemTitle}>{fact.title}</h3>
                <p className={styles.factItemText}>{fact.fact}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default SpaceScienceFacts;
