import React from 'react';
import './contact.css'; // Make sure the CSS file is imported for styling
import { FaInstagram, FaLinkedin } from 'react-icons/fa'; // Import Instagram and LinkedIn icons
import Header from '../Header';

const Contact = () => {
  return (
    <>
      <Header />
      <div className="contact-page">
        <div className="contact-content">
          <h1>Contact Us</h1>

          <section className="contact-row">
            <div className="contact-details">
              <h2>Our Company Address</h2>
              <p>A 4 ABL WORKSPACE SECTOR 4 NOIDA , INDIA . PINCODE: 201009</p>

              <h2>Phone Number</h2>
              <p>+919773690707</p>

              <h2>Email</h2>
              <p>
                <a href="mailto:info@codimaths.com">info@codimaths.com</a>
              </p>
            </div>

            {/* Map Container */}
            <div className="map-container">
              <div className="map-box">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.572799763537!2d77.32270797543445!3d28.58258827569209!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5c634e7d5c9%3A0x541263cf553b858e!2sABL%20Workspaces!5e0!3m2!1sen!2sin!4v1737708061688!5m2!1sen!2sin"
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </section>

          {/* Social Media Links */}
          <section className="social-links">
            <h3>Follow Us</h3>
            <div className="social-icons">
              <a href="https://www.instagram.com/codimaths_gainwisdom/" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={40} color="#4da7e7" className="social-icon" />
              </a>
              <a href="https://www.linkedin.com/company/codimaths/about/" target="_blank" rel="noopener noreferrer">
                <FaLinkedin size={40} color="#4da7e7" className="social-icon" />
              </a>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Contact;
