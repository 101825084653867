import React from 'react';
import { Link } from 'react-router-dom'; 
import styles from './Header.module.css'; // Import the styles

function Header() {
  return (
    <nav className={styles.navbar}>
      <Link className={styles['navbar-logo']} to="/">
        <img className={styles.logologo} src={require('./images/logo.png')} alt="Logo" />
        <img className={styles['logo-text']} src={require('./images/codi-text.png')} alt="Logo-text" />
      </Link>
      <ul className={styles['navbar-links']}>
        <li><a href="/">Home</a></li>
        <li><Link to="/contact">Contact</Link></li>
      </ul>
    </nav>
  )
}

export default Header;
